import Swal from 'sweetalert2';

//? options

// title: 'Do you want to save the changes?',
// showDenyButton: true,
// showCancelButton: true,
// confirmButtonText: 'Save',
// denyButtonText: `Don't save`,

/**
 * Custom Alert that displays a custom modal with swal
 *
 * @param {swalConfiguration} swal configuration
 * @returns {void}
 */
const CustomAlert = ({
  title,
  text = '',
  icon = 'success',
  showConfirmButton = true,
  showCloseButton = true,
  showDenyButton = false,
  showCancelButton = false,
  confirmButtonText = 'Continuar',
  denyButtonText = 'Cancelar',
  // optional props
  handleThen,
  handleConfirm,
  handleCancel,
  theme,
  ...props
}) => {
  {
    let alert = {
      title,
      text,
      icon,
      showConfirmButton,
      showCloseButton,
      confirmButtonText,
      showCancelButton,
      showDenyButton,
      denyButtonText
    };
    // Swal.fire(alert);
    if (props.timer) {
      alert.timerProgressBar = true;
      // (alert.didOpen = () => {
      //   Swal.showLoading();
      // });
    }
    let style = {};
    if (theme) {
      let { background, text } = theme.palette;
      style = { background: background.paper, color: text.primary };
    }

    Swal.fire({ ...alert, ...props, ...style }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      handleThen?.();
      if (result.isConfirmed) {
        handleConfirm?.();
      } else if (result.isDenied) {
        handleCancel?.();
        // Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
};

export default CustomAlert;
