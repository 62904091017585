import React, { useReducer, createContext } from 'react';

import api from 'service/httpService';

import { SET_LOADING, CREATE_APPOINTMENT, SET_ERROR, CLEAR_STATE, GET_APPOINTMENT, UPDATE_APPOINTMENT, GET_APPOINTMENTS } from '../types';
import { SUCCESS_STATUS_ICON } from 'components/CustomAlert/constants/status';
import { DEFAULT_APPOINTMENT_STATE } from './constants';

import CustomAlert from 'components/CustomAlert';

import appointmentReducer from './appointmentReducer';

export const AppointmentContext = createContext();

const AppointmentState = (props) => {
  const [state, dispatch] = useReducer(appointmentReducer, DEFAULT_APPOINTMENT_STATE);

  const createAppointment = async ({ appointment, handleThen }) => {
    setLoading();
    try {
      const res = await api.post(`/appointment`, appointment);
      dispatch({ type: CREATE_APPOINTMENT, payload: res });
      CustomAlert({
        title: 'appointment created',
        icon: SUCCESS_STATUS_ICON,
        handleThen
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateAppointment = async ({ id, appointment, handleThen }) => {
    setLoading();
    try {
      const res = await api.put(`/appointment/${id}`, appointment);
      dispatch({ type: UPDATE_APPOINTMENT, payload: res });
      if (handleThen)
        CustomAlert({
          title: 'appointment updated',
          icon: SUCCESS_STATUS_ICON,
          handleThen
        });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getAppointment = async (appointmentId) => {
    setLoading();
    try {
      const res = await api.get(`/appointment/${appointmentId}`);
      dispatch({ type: GET_APPOINTMENT, payload: res });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getAppointments = async (query) => {
    clearState();
    setLoading();
    try {
      const res = await api.post(`/appointment/advancedSearchDocuments`, query);
      dispatch({ type: GET_APPOINTMENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <AppointmentContext.Provider
      value={{
        ...state,
        setLoading,
        clearState,
        createAppointment,
        updateAppointment,
        getAppointment,
        getAppointments
      }}
    >
      {props.children}
    </AppointmentContext.Provider>
  );
};

export default AppointmentState;
