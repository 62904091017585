import { CREATE_TOKEN, CLEAR_STATE, SET_LOADING, SET_ERROR, GET_TOKEN, UPDATE_TOKEN } from '../types';
import { DEFAULT_TOKEN_STATE } from './TokenState';
//eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case CREATE_TOKEN: {
      return {
        ...state,
        token: action.payload,
        loading: false,
        error: null
      };
    }
    case GET_TOKEN: {
      return {
        ...state,
        token: action.payload,
        loading: false,
        error: null
      };
    }
    case UPDATE_TOKEN: {
      return {
        ...state,
        token: action.payload,
        loading: false,
        error: null
      };
    }
    case CLEAR_STATE:
      return {
        ...state,
        ...DEFAULT_TOKEN_STATE
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
