import React, { useReducer, createContext } from 'react';
import {
  SET_LOADING,
  CREATE_LEAD,
  SET_ERROR,
  CLEAR_STATE,
  GET_LEAD,
  UPDATE_LEAD,
  GET_LEADS,
  SEND_EMAIL,
  CLEAR_SNACKBAR,
  CREATE_NOTE,
  UPDATE_NOTE,
  DELETE_NOTE
} from '../types';
import { DEFAULT_LEAD_STATE } from './constants';
import leadReducer from './leadReducer';
import api from '../../api/api';
import { sendToAwsS3 } from 'utils/aws';

export const LeadContext = createContext();

const LeadState = (props) => {
  const [state, dispatch] = useReducer(leadReducer, DEFAULT_LEAD_STATE);

  const setSnackbarInterval = (time = 1000) => {
    setTimeout(() => dispatch({ type: CLEAR_SNACKBAR }), time);
  };

  const createNote = async (leadId, note) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    try {
      const res = await api.post(`/lead/${leadId}/note`, note, config);
      dispatch({ type: CREATE_NOTE, payload: res.data.data, successMessage: res.data.message });
      setSnackbarInterval();
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
      setSnackbarInterval(5000);
    }
  };

  const updateNote = async (leadId, noteId, note) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    try {
      const res = await api.put(`/lead/${leadId}/note/${noteId}`, note, config);
      dispatch({ type: UPDATE_NOTE, payload: res.data.data, successMessage: res.data.message });
      setSnackbarInterval();
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
      setSnackbarInterval(5000);
    }
  };

  const deleteNote = async (leadId, noteId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    try {
      const res = await api.delete(`/lead/${leadId}/note/${noteId}`, config);
      dispatch({ type: DELETE_NOTE, payload: res.data.data, successMessage: res.data.message });
      setSnackbarInterval();
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
      setSnackbarInterval(5000);
    }
  };

  const sendEmail = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const { attachments = [] } = values;

      const attachmentsUrls = await Promise.all(attachments.map((file) => sendToAwsS3(file, config, true)));

      await api.post(`/lead/sendEmail`, { ...values, attachments: attachmentsUrls }, config);
      dispatch({ type: SEND_EMAIL });
      setSnackbarInterval();
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
      setSnackbarInterval(5000);
    }
  };

  //Create lead
  const createLead = async ({ ...lead }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.post(`/lead`, { ...lead }, config);
      dispatch({ type: CREATE_LEAD, payload: res.data.data, successMessage: res.data.message });
      setSnackbarInterval();
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
      setSnackbarInterval(5000);
    }
  };

  const updateLead = async (id, values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.put(`/lead/${id}`, { ...values }, config);
      dispatch({ type: UPDATE_LEAD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getLead = async (leadId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.get(`/lead/${leadId}`, config);
      dispatch({ type: GET_LEAD, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getLeads = async (query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.post(`/lead/advancedSearchDocuments`, {}, config);
      dispatch({ type: GET_LEADS, payload: res.data.data.data, count: res.data.data.pagination.total });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <LeadContext.Provider
      value={{
        ...state,
        setLoading,
        clearState,
        createLead,
        updateLead,
        getLead,
        getLeads,
        sendEmail,
        createNote,
        updateNote,
        deleteNote
      }}
    >
      {props.children}
    </LeadContext.Provider>
  );
};

export default LeadState;
