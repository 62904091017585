import React, { useReducer } from 'react';
import TemplateContext from './templateContext';
import TemplateReducer from './templateReducer';
import api from '../../api/api';
import {
  CREATE_TEMPLATE,
  GET_TEMPLATE,
  DELETE_TEMPLATE,
  UPDATE_TEMPLATE,
  SET_ERROR,
  GET_TEMPLATE_V3,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

const TemplateState = (props) => {
  const initialState = {
    templates: [],
    template: {},
    loading: false,
    error: null,
    count: null
  };

  const [state, dispatch] = useReducer(TemplateReducer, initialState);

  //Get Templates
  const getTemplateV3 = async (values) => {
    setLoading();
    try {
      const res = await api.post(`/templates/getTemplateV3`, { ...values });
      const payload = {
        data: res.data.results.data,
        total: res.data.results.pagination.total
      };
      dispatch({ type: GET_TEMPLATE_V3, payload: payload });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err });
    }
  };

  //Get Template
  const getTemplate = async (templateId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.get(`/templates/${templateId}`, config);
      dispatch({ type: GET_TEMPLATE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Delete Template
  const deleteTemplate = async (templateId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.delete(`/templates/${templateId}`, config);
      dispatch({
        type: DELETE_TEMPLATE,
        payload: res.data.deletedId
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Create TemplateTwilio
  const createTemplate = async (template) => {
    console.log(template);

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    clearState();
    setLoading();

    try {
      const res = await api.post(`/templates`, { ...template }, config);
      dispatch({ type: CREATE_TEMPLATE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Update TemplateTwilio
  const updateTemplate = async (template, templateId) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.put(`/templates/${templateId}`, { ...template }, config);
      dispatch({ type: UPDATE_TEMPLATE, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <TemplateContext.Provider
      value={{
        loading: state.loading,
        templates: state.templates,
        template: state.template,
        error: state.error,
        count: state.count,
        getTemplateV3,
        createTemplate,
        getTemplate,
        deleteTemplate,
        updateTemplate,
        clearState,
        setLoading
      }}
    >
      {props.children}
    </TemplateContext.Provider>
  );
};

export default TemplateState;
