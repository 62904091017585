import { CLEAR_STATE, SET_LOADING, SET_ERROR, GET_REPORT, CREATE_REPORT, SET_SUCCESS } from '../types';
import { DEFAULT_REPORT_STATE } from './constants';

export default (state, action) => {
  switch (action.type) {
    case SET_SUCCESS:
      return {
        ...state,
        success: null
      };
    case CREATE_REPORT:
      return {
        ...state,
        report: action.payload,
        loading: false,
        success: action.successMessage
      };
    case GET_REPORT: {
      return {
        ...state,
        report: action.payload,
        loading: false,
        error: null
      };
    }
    case CLEAR_STATE:
      return DEFAULT_REPORT_STATE;
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
