import React, { useReducer } from 'react';
import api from '../../api/api';
import HookContext from './hookContext';
import HookReducer from './hookReducer';
import { GET_HOOK, GET_HOOKS, UPDATE_HOOK, SEND_HOOK, CLEAR_STATE, SET_ERROR, SET_LOADING } from '../types';

const HookState = (props) => {
  const initialState = {
    hooks: [],
    hook: {},
    loading: false,
    error: null,
    success: null
  };

  const [state, dispatch] = useReducer(HookReducer, initialState);

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  //Clear State
  const clearHookState = () => dispatch({ type: CLEAR_STATE });

  //get Hooks by User
  const getHooksByUser = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.get(`/hooks/user/${values}`, config);
      dispatch({ type: GET_HOOKS, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //get Hooks by team
  const getHooksByTeam = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.get(`/hooks/team/${values}`, config);
      dispatch({ type: GET_HOOKS, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //get Hook
  const getHook = async (id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearHookState();
    setLoading();
    try {
      const res = await api.get(`/hooks/${id}`, config);
      dispatch({ type: GET_HOOK, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateHook = async (id, values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.put(`/hooks/${id}`, { ...values }, config);
      dispatch({ type: UPDATE_HOOK, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const sendInfoHook = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post('/hooks/sendInfo', { ...values }, config);
      dispatch({ type: SEND_HOOK, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const setHook = async (hook) => dispatch({ type: GET_HOOK, payload: hook });

  const getAdvancedSearchHook = async (query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearHookState();
    setLoading();
    try {
      const res = await api.post(`/accounts/advancedSearchDocument`, query, config);
      dispatch({ type: GET_HOOK, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getAdvancedSearchHooks = async (query) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/accounts/advancedSearchDocuments`, query, config);
      let response = res?.data?.data;

      dispatch({ type: GET_HOOKS, payload: response?.data, count: response?.count });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  return (
    <HookContext.Provider
      value={{
        loading: state.loading,
        hooks: state.hooks,
        hook: state.hook,
        error: state.error,
        success: state.success,
        getHook,
        getHooksByTeam,
        getHooksByUser,
        setLoading,
        sendInfoHook,
        updateHook,
        clearHookState,
        setHook,
        getAdvancedSearchHook,
        getAdvancedSearchHooks
      }}
    >
      {props.children}
    </HookContext.Provider>
  );
};

export default HookState;
