import { useIntl } from 'react-intl';

const useTranslation = () => {
  const intl = useIntl();
  return {
    translate: (id, values = {}) => intl.formatMessage({ id, defaultMessage: `${id}` }, values)
  };
};

export { useTranslation };
