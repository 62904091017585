const FB_DEFAULT_STATE = {
  loading: false,
  accounts: null,
  leadgen_forms: null,
  leadgens: null,
  leadgen_form: null,
  campaigns: null,
  adsets: null,
  ads: null,
  //Facebook Documents saved fields
  facebookLeadgenForms: null,
  facebookLeadgenForm: null,
  facebookPage: null
};

export default FB_DEFAULT_STATE;
