import React, { useReducer } from 'react';
import AlertContext from './alertContext';
import AlertReducer from './alertReducer';
import { CLEAR_STATE, REMOVE_ALERT, SET_ALERT } from '../types';

const AlertState = (props) => {
  const initialState = {
    alert: {
      message: null,
      type: null,
      title: null
    }
  };

  const [state, dispatch] = useReducer(AlertReducer, initialState);

  const clearState = () => dispatch({ type: CLEAR_STATE });

  const setAlert = async (value) => {
    clearState();
    dispatch({ type: SET_ALERT, payload: value });
    removeAlert();
  };

  const removeAlert = () => setTimeout(() => dispatch({ type: REMOVE_ALERT }), 3000);

  return (
    <AlertContext.Provider
      value={{
        alert: state.alert,
        setAlert,
        clearState,
        removeAlert
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
