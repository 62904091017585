import { SET_ALERT, REMOVE_ALERT, CLEAR_STATE } from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload
      };
    case CLEAR_STATE:
      return {
        ...state,
        alert: {
          message: null,
          type: null,
          title: null
        }
      };
    case REMOVE_ALERT:
      return {
        alert: {
          message: null,
          type: null,
          title: null
        }
      };
    default:
      return state;
  }
};

export default reducer;
