import { useReducer, createContext } from 'react';
import { SET_LOADING, SET_ERROR, CLEAR_STATE, GET_REPORT, CREATE_REPORT, SET_SUCCESS } from '../types';
import { DEFAULT_REPORT_STATE } from './constants';
import reportReducer from './reportReducer';
import api from '../../api/api';
import { IReport } from './types';

export const ReportContext = createContext(DEFAULT_REPORT_STATE);

const ReportState = (props) => {
  const [state, dispatch] = useReducer(reportReducer, DEFAULT_REPORT_STATE);

  const getReport = async (reportId: string) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.get(`/reports/${reportId}`, config);
      dispatch({ type: GET_REPORT, payload: res.data.data });
    } catch (error) {
      if (error instanceof Error) dispatch({ type: SET_ERROR, payload: error.message });
    }
  };

  const createReport = async (values: IReport) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.post(`/reports`, { ...values }, config);
      dispatch({ type: CREATE_REPORT, payload: res.data.data, successMessage: 'reportCreated' });
      setSuccess();
    } catch (error) {
      if (error instanceof Error) dispatch({ type: SET_ERROR, payload: error.message });
    }
  };

  const clearState = () => dispatch({ type: CLEAR_STATE });

  const setLoading = () => dispatch({ type: SET_LOADING });

  const setSuccess = () => setTimeout(() => dispatch({ type: SET_SUCCESS }), 1000);

  return (
    <ReportContext.Provider
      value={{
        ...state,
        setLoading,
        clearState,
        getReport,
        createReport,
        setSuccess
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
};

export default ReportState;
