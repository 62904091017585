import CustomAlert from 'components/CustomAlert';
import { CREATE_SALE, CLEAR_STATE, SET_LOADING, SET_ERROR, GET_SALE, UPDATE_SALE, GET_SALES } from '../types';
import { DEFAULT_SALE_STATE } from './constants';

export default (state, action) => {
  switch (action.type) {
    case CREATE_SALE: {
      state.sales.unshift(action.payload);
      return {
        ...state,
        sale: action.payload,
        loading: false,
        error: null
      };
    }
    case GET_SALE: {
      return {
        ...state,
        sale: action.payload,
        loading: false,
        error: null
      };
    }

    case GET_SALES: {
      return {
        ...state,
        sales: action.payload,
        loading: false,
        error: null
      };
    }
    case UPDATE_SALE: {
      state.sales = state.sales.map((sale) => {
        if (sale?._id === action?.payload?._id) return action.payload;
        return sale;
      });
      return {
        ...state,
        sale: action.payload,
        loading: false,
        error: null
      };
    }
    case CLEAR_STATE:
      return {
        ...state,
        ...DEFAULT_SALE_STATE
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ERROR:
      CustomAlert({
        title: action?.payload?.error || action.payload,
        icon: 'error'
      });
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
