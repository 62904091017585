import { GET_HOOK, GET_HOOKS, UPDATE_HOOK, SEND_HOOK, CLEAR_STATE, SET_ERROR, SET_LOADING } from '../types';

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case GET_HOOK:
      return {
        ...state,
        hook: action.payload,
        loading: false,
        error: null
      };
    case GET_HOOKS:
      return {
        ...state,
        hooks: action.payload,
        loading: false,
        error: null
      };
    case UPDATE_HOOK:
      return {
        ...state,
        hook: action.payload,
        loading: false,
        error: null
      };
    case SEND_HOOK:
      return {
        ...state,
        hook: action.payload,
        loading: false,
        error: null,
        success: 'HookSuccess'
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CLEAR_STATE:
      return {
        hook: {},
        hooks: [],
        loading: false,
        error: null,
        success: null
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
