import React, { useReducer, createContext } from 'react';

import api from 'service/httpService';

import CustomAlert from 'components/CustomAlert';

import { SET_LOADING, CREATE_TASK, SET_ERROR, CLEAR_STATE, GET_TASK, UPDATE_TASK, GET_TASKS } from '../types';
import { SUCCESS_STATUS_ICON } from 'components/CustomAlert/constants/status';
import { DEFAULT_TASK_STATE } from './constants';

import taskReducer from './taskReducer';

export const TaskContext = createContext();

const TaskState = (props) => {
  const [state, dispatch] = useReducer(taskReducer, DEFAULT_TASK_STATE);

  //Create task
  const createTask = async ({ newTask, handleThen }) => {
    setLoading();
    try {
      const res = await api.post(`/task`, newTask);
      dispatch({ type: CREATE_TASK, payload: res });
      CustomAlert({
        title: 'task created',
        icon: SUCCESS_STATUS_ICON,
        handleThen
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateTask = async ({ id, task, handleThen }) => {
    setLoading();
    try {
      const res = await api.put(`/task/${id}`, task);
      dispatch({ type: UPDATE_TASK, payload: res });
      if (handleThen)
        CustomAlert({
          title: 'task updated',
          icon: SUCCESS_STATUS_ICON,
          handleThen
        });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getTask = async (taskId) => {
    setLoading();
    try {
      const res = await api.get(`/task/${taskId}`);
      dispatch({ type: GET_TASK, payload: res });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getTasks = async (query) => {
    clearState();
    setLoading();
    try {
      const res = await api.post(`/task/advancedSearchDocuments`, query);
      dispatch({ type: GET_TASKS, payload: res.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <TaskContext.Provider
      value={{
        ...state,
        setLoading,
        clearState,
        createTask,
        updateTask,
        getTask,
        getTasks
      }}
    >
      {props.children}
    </TaskContext.Provider>
  );
};

export default TaskState;
