import React, { forwardRef } from 'react';
import { Alert } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ISnackBar, TAlertStyles } from './types';
import { SUCCESS_STATUS_ICON } from 'components/CustomAlert/constants/status';

const alertStyles: TAlertStyles = {
  success: {
    severity: 'success',
    backgroundColor: 'rgb(0, 230, 118)',
    textColor: 'rgba(0,0,0,0.87)'
  },
  error: {
    severity: 'error',
    backgroundColor: 'rgb(244, 67, 54)',
    textColor: 'rgb(255, 255, 255)'
  },
  info: {
    severity: 'info',
    backgroundColor: 'rgb(33, 150, 243)',
    textColor: 'rgb(255, 255, 255)'
  },
  warning: {
    severity: 'warning',
    backgroundColor: 'rgb(255, 229, 127)',
    textColor: 'rgba(0,0,0,0.87)'
  }
};

export const SnackBar = forwardRef<HTMLDivElement, ISnackBar>(({ id, message, type = SUCCESS_STATUS_ICON }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const { severity, backgroundColor, textColor } = alertStyles[type] || alertStyles.success;

  return (
    <Alert
      ref={ref}
      onClose={() => closeSnackbar(id)}
      severity={severity}
      variant="filled"
      sx={{
        backgroundColor: backgroundColor,
        color: textColor,
        fontWeight: 'bold'
      }}
    >
      {message}
    </Alert>
  );
});
