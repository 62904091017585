export const CLEAR_ALL_USER_STATE = 'all';

export const USERS_STATE = 'users';
export const USER_STATE = 'user';
export const USERS_COUNT_STATE = 'count';
export const USERS_EMAIL_STATE = 'usersEmail';

export const DEFAULT_USER_STATE = {
  [USERS_STATE]: [],
  [USER_STATE]: {},
  [USERS_EMAIL_STATE]: [],
  [USERS_COUNT_STATE]: null,
  success: false,
  loading: false,
  error: null
};
