import React, { useReducer } from 'react';
import TokenContext from './tokenContext';
import tokenReducer from './tokenReducer';
import api from '../../api/api';
import { SET_LOADING, CREATE_TOKEN, SET_ERROR, CLEAR_STATE, GET_TOKEN, UPDATE_TOKEN } from '../types';

export const DEFAULT_TOKEN_STATE = {
  tokens: [],
  token: {},
  loading: false,
  error: null,
  count: 0
};

const TokenState = (props) => {
  const [state, dispatch] = useReducer(tokenReducer, DEFAULT_TOKEN_STATE);

  //Create token
  const createToken = async ({ ...token }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.post(`/token`, { ...token }, config);
      dispatch({ type: CREATE_TOKEN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };
  const getTokenByUser = async (userId, source) => {
    clearState();
    setLoading();
    try {
      const res = await api.get(`/token/${userId}?source=${source}`);
      dispatch({ type: GET_TOKEN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getTokenByAdAccount = async (adAccountId) => {
    clearState();
    setLoading();
    try {
      const res = await api.get(`/token/adAccount/${adAccountId}`);
      dispatch({ type: GET_TOKEN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateTokenByAdAccount = async (adAccountId, values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };

    setLoading();
    try {
      const res = await api.put(`/token/adAccount/${adAccountId}`, { ...values }, config);
      dispatch({ type: GET_TOKEN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getTokenByTeam = async (teamId, source) => {
    clearState();
    setLoading();
    try {
      const res = await api.get(`/token/team/${teamId}?source=${source}`);
      dispatch({ type: GET_TOKEN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const updateToken = async (id, values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearState();
    setLoading();
    try {
      const res = await api.put(`/token/${id}`, { ...values }, config);
      dispatch({ type: UPDATE_TOKEN, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getTokenAdvancedSearch = async (query) => {
    clearState();
    setLoading();
    try {
      const res = await api.post(`/token/advancedSearch`, query);
      dispatch({ type: GET_TOKEN, payload: res.data.data });
    } catch (err) {
      console;
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <TokenContext.Provider
      value={{
        loading: state.loading,
        tokens: state.tokens,
        token: state.token,
        error: state.error,
        count: state.count,
        setLoading,
        clearState,
        createToken,
        getTokenByUser,
        updateToken,
        getTokenByAdAccount,
        getTokenByTeam,
        updateTokenByAdAccount,
        getTokenAdvancedSearch
      }}
    >
      {props.children}
    </TokenContext.Provider>
  );
};

export default TokenState;
