import _ from 'lodash';
import { IConversation } from 'types/conversations';

type TObjectWithID = IConversation;

/**
 * Filters an array of objects to keep only unique objects based on the '_id' property.
 *
 * @param {Array<Object>} array - The array of objects to filter.
 * @returns {Array<Object>} A new array containing only unique objects based on the '_id' property.
 */

export const filterUniqueID = (array: TObjectWithID[]) => {
  return _.uniqBy(array, '_id');
};
