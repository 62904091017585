export const DEFAULT_LEAD_STATE = {
  leads: [],
  lead: null,
  notes: [],
  loading: false,
  error: null,
  count: 0,
  emailSentSuccesfully: false,
  success: null
};
