import {
  CREATE_TEMPLATE,
  GET_TEMPLATE,
  DELETE_TEMPLATE,
  UPDATE_TEMPLATE,
  GET_TEMPLATE_V3,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_TEMPLATE_V3:
      return {
        ...state,
        templates: action.payload.data,
        loading: false,
        count: action.payload.count,
        error: null
      };
    case GET_TEMPLATE:
      return {
        ...state,
        template: action.payload,
        loading: false,
        error: null
      };
    case CREATE_TEMPLATE:
      return {
        ...state,
        template: action.payload,
        loading: false,
        error: null
      };
    case DELETE_TEMPLATE:
      state.templates = state.templates.filter((template) => template._id.toString() !== action.payload.toString());
      return {
        ...state,
        template: null,
        loading: false,
        error: null
      };
    case UPDATE_TEMPLATE:
      return {
        ...state,
        template: action.payload,
        loading: false,
        error: null
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CLEAR_STATE:
      return {
        templates: [],
        template: {},
        loading: false,
        error: null
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
