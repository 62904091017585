import { LAYOUT_CONST } from './constant';
import { ConfigProps } from './types/config';

export const BASE_PATH = '';

export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark'
};

export const DASHBOARD_PATH = 'dashboard';

export const LOGIN_PATH = '/login';

export const HORIZONTAL_MAX_ITEM = 7;

export const ICON_STYLES = {
  width: '20px',
  height: '20px',
  strokeWidth: '1.5px'
};

const config: ConfigProps = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: 'light', // light, dark
  presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false
};

export default config;
