import React, { useReducer, createContext } from 'react';

import api from 'service/httpService';

import CustomAlert from 'components/CustomAlert';

import { SET_LOADING, CREATE_SALE, SET_ERROR, CLEAR_STATE, GET_SALE, UPDATE_SALE, GET_SALES } from '../types';

import { SUCCESS_STATUS_ICON } from 'components/CustomAlert/constants/status';
import { DEFAULT_SALE_STATE } from './constants';

import saleReducer from './saleReducer';

export const SaleContext = createContext();

const SaleState = (props) => {
  const [state, dispatch] = useReducer(saleReducer, DEFAULT_SALE_STATE);

  //Create sale
  const createSale = async ({ newSale, handleThen = () => {} }) => {
    setLoading();
    try {
      const res = await api.post(`/sale`, newSale);
      dispatch({ type: CREATE_SALE, payload: res });
      CustomAlert({
        title: 'sale created',
        icon: SUCCESS_STATUS_ICON,
        handleThen
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: SET_ERROR, payload: err?.response?.data });
    }
  };

  const updateSale = async ({ id, sale, handleThen }) => {
    setLoading();
    try {
      const res = await api.put(`/sale/${id}`, sale);
      dispatch({ type: UPDATE_SALE, payload: res });
      if (handleThen)
        CustomAlert({
          title: 'sale updated',
          icon: SUCCESS_STATUS_ICON,
          handleThen
        });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getSale = async (saleId) => {
    setLoading();
    try {
      const res = await api.get(`/sale/${saleId}`);
      dispatch({ type: GET_SALE, payload: res });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getSales = async (query) => {
    clearState();
    setLoading();
    try {
      const res = await api.post(`/sale/advancedSearchDocuments`, query);
      dispatch({ type: GET_SALES, payload: res.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <SaleContext.Provider
      value={{
        ...state,
        setLoading,
        clearState,
        createSale,
        updateSale,
        getSale,
        getSales
      }}
    >
      {props.children}
    </SaleContext.Provider>
  );
};

export default SaleState;
