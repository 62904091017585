import CustomAlert from '../../components/CustomAlert';
import ErrorShowTime from '../../constants/ErrorShowTime';
import {
  GET_AD_ACCOUNT,
  SAVE_AD_ACCOUNT,
  UPDATE_AD_ACCOUNT,
  GET_AD_ACCOUNTS,
  CLEAR_STATE,
  SET_ERROR,
  SET_LOADING,
  SUBSCRIBE_WEBHOOK,
  REMOVE_ACCOUNT_FROM_TEAM,
  GET_TEAM_AD_ACCOUNTS
} from '../types';

import { ADACCOUNTS_TYPE, DEFAULT_ADACCOUNT_STATE, TEAM_ADACCOUNTS_TYPE } from './adAccountState';

const setUpdatedAccount = ({ state, accountType, updatedAccount }) => {
  let documentIndex = state?.[accountType].findIndex((team) => team?._id === updatedAccount?._id);
  if (documentIndex >= 0) state[accountType][documentIndex] = updatedAccount;
  else {
    if (accountType === TEAM_ADACCOUNTS_TYPE) state.teamAdAccountsCount += 1;
    state[accountType].push(updatedAccount);
  }
  return state;
};

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case SUBSCRIBE_WEBHOOK: {
      return {
        ...state,
        loading: false,
        error: null,
        success: true
      };
    }
    case SAVE_AD_ACCOUNT: {
      return {
        ...state,
        adAccount: action.payload,
        adAccounts: [action.payload, ...state?.adAccounts],
        loading: false,
        error: null,
        success: true
      };
    }
    case GET_AD_ACCOUNT: {
      return {
        ...state,
        adAccount: action.payload,
        loading: false,
        error: null
      };
    }

    case GET_AD_ACCOUNTS: {
      return {
        ...state,
        adAccounts: action.payload,
        count: action?.count || 0,
        loading: false,
        error: null
      };
    }

    case GET_TEAM_AD_ACCOUNTS: {
      return {
        ...state,
        teamAdAccounts: action.payload,
        teamAdAccountsCount: action?.count || 0,
        loading: false,
        error: null
      };
    }
    case REMOVE_ACCOUNT_FROM_TEAM:
    case UPDATE_AD_ACCOUNT: {
      state = setUpdatedAccount({ state, accountType: ADACCOUNTS_TYPE, updatedAccount: action.payload });
      state = setUpdatedAccount({ state, accountType: TEAM_ADACCOUNTS_TYPE, updatedAccount: action.payload });
      if (action.teamAdAccountsCount) state.teamAdAccountsCount = action.teamAdAccountsCount;
      return {
        ...state,
        adAccount: action.payload,
        loading: false,
        error: null,
        success: true
      };
    }
    case CLEAR_STATE:
      return {
        ...state,
        ...DEFAULT_ADACCOUNT_STATE
      };
    case SET_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case SET_ERROR: {
      CustomAlert({
        title: action?.payload,
        icon: 'error',
        timer: ErrorShowTime
      });
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false
      };
    }
    default:
      return { ...state };
  }
};
