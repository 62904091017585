import { CREATE_POCKET, GET_POCKET, DELETE_POCKET, UPDATE_POCKET, GET_POCKETS, SET_ERROR, CLEAR_STATE, SET_LOADING } from '../types';
import { LOCAL_STORAGE_POCKET_DRAFT } from './constants';
// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case GET_POCKETS:
      return {
        ...state,
        pockets: action.payload.data,
        loading: false,
        count: action.payload.count,
        error: null
      };
    case GET_POCKET:
      return {
        ...state,
        pocket: action.payload,
        loading: false,
        error: null
      };
    case CREATE_POCKET:
      localStorage.removeItem(LOCAL_STORAGE_POCKET_DRAFT);
      return {
        ...state,
        pocket: action.payload,
        loading: false,
        error: null
      };
    case DELETE_POCKET:
      state.pockets = state.pockets.filter((pocket) => pocket._id.toString() !== action.payload.toString());
      return {
        ...state,
        pocket: null,
        loading: false,
        error: null
      };
    case UPDATE_POCKET:
      localStorage.removeItem(LOCAL_STORAGE_POCKET_DRAFT);
      return {
        ...state,
        pocket: action.payload,
        loading: false,
        error: null
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CLEAR_STATE:
      return {
        pockets: [],
        pocket: {},
        loading: false,
        error: null
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
