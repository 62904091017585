import { IReport } from '../types';
import { TReportInitialState } from './types';

export const DEFAULT_REPORT_STATE: TReportInitialState = {
  report: null,
  loading: false,
  error: null,
  success: null,
  setSuccess: () => {},
  getReport: (reportId: string) => {},
  createReport: (values: IReport) => {}
};
