import FB_DEFAULT_STATE from '../../constants/Facebook/FB_DEFAULT_STATE';
import {
  GET_FB_RESOURCE,
  GET_FACEBOOK_DOCUMENT,
  CREATE_FACEBOOK_DOCUMENT,
  UPDATE_FACEBOOK_DOCUMENT,
  RESEND_FACEBOOK_LEAD,
  CLEAR_STATE,
  SET_LOADING,
  SET_ERROR,
  SET_LOADING_LEADGEN,
  SET_ERROR_LEADGEN
} from '../types';
import sortArrayByKey from '../../utils/sortArrayByKey';
import CustomAlert from '../../components/CustomAlert';

const SORT_BY_NAME = ['accounts'];

const reducer = (state, action) => {
  let { type, resourceName, payload } = action;
  let updatedState = {};
  switch (type) {
    case RESEND_FACEBOOK_LEAD:
      updatedState = {};
      let leads = state?.facebookLeadgenForm?.leads || [];
      let lead_index = leads.findIndex((leadgen_form) => leadgen_form?.id === payload);
      let new_leadgen_form = { id: payload, status: 'created' };
      if (lead_index !== -1) leads[lead_index] = new_leadgen_form;
      else leads.push(new_leadgen_form);
      updatedState.facebookLeadgenForm = {
        ...state.facebookLeadgenForm,
        leads
      };
      return {
        ...state,
        ...updatedState,
        loading: false
      };
    case GET_FACEBOOK_DOCUMENT:
    case UPDATE_FACEBOOK_DOCUMENT:
    case CREATE_FACEBOOK_DOCUMENT:
      updatedState = {};
      if (resourceName === 'facebookLeadgenForm' && state?.facebookPage) {
        let leadgen_forms = state.facebookPage?.leadgen_forms || [];
        let leadgen_form_index = leadgen_forms.findIndex((leadgen_form) => leadgen_form?.id === payload.id);
        let new_leadgen_form = { id: payload.id, status: payload.status };
        if (leadgen_form_index !== -1) leadgen_forms[leadgen_form_index] = new_leadgen_form;
        else leadgen_forms.push(new_leadgen_form);
        updatedState.facebookPage = {
          ...state.facebookPage,
          leadgen_forms
        };
      }
      return {
        ...state,
        ...updatedState,
        [resourceName]: payload,
        loading: false
      };
    case GET_FB_RESOURCE:
      if (SORT_BY_NAME.includes(resourceName)) {
        payload.data = sortArrayByKey(payload.data, 'name');
      }
      return {
        ...state,
        [resourceName]: payload,
        loading: false
      };
    case CLEAR_STATE:
      if (resourceName)
        return {
          ...state,
          [resourceName]: FB_DEFAULT_STATE[resourceName],
          loading: false
        };
      else
        return {
          ...state,
          ...FB_DEFAULT_STATE,
          loading: false
        };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_LOADING_LEADGEN:
      let { leadgenId, loading } = payload;
      let newLeadgens = state?.leadgens?.data || [];
      let leadIndex = newLeadgens.findIndex((leadgen) => leadgen?.id === leadgenId);
      if (leadIndex !== -1) {
        newLeadgens[leadIndex].loading = loading;
      }
      return {
        leadgens: { ...state.leadgens, data: newLeadgens },
        ...state
      };

    case SET_ERROR:
      CustomAlert({
        title: action?.payload?.error || action.payload,
        icon: 'error'
      });
      return {
        ...state,
        error: payload,
        loading: false
      };

    case SET_ERROR_LEADGEN:
      let { leadgen, error } = payload;
      let errorMessage = error.error || error;
      let newLeadgensError = state?.leadgens?.data || [];

      let leadIndexError = newLeadgensError.findIndex((currentLeadgen) => currentLeadgen?.id === leadgen?.id);
      if (leadIndexError !== -1) {
        newLeadgensError[leadIndexError].error = errorMessage;
        newLeadgensError[leadIndexError].loading = false;
      }
      CustomAlert({
        title: errorMessage,
        icon: 'error'
      });
      return {
        ...state,
        leadgens: { ...state.leadgens, data: newLeadgensError },
        error,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
