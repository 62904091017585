import { FACEBOOK_LEAD_ADS, SOURCE_KRAQUEN, SOURCE_SALES_U } from 'constants/Source';
import { POCKET_EVENT_TRIGGER_FACEBOOK_NEW_LEAD } from './facebookEvents';
import { POCKET_EVENT_ACTION_KRAQUEN_SAVE_LEAD } from './kraquenEvents';
import { IPocket } from '../types';
import { ITableQuery } from 'components/Shared/EnchancedTable/types';
import { POCKET_EVENT_ACTION_SALES_U_WEBHOOK } from './salesUEvents';

interface IPocketState {
  pockets: IPocket[];
  pocket: IPocket | null;
  loading: boolean;
  error: any;
  count: number;
  success: boolean;
  setLoading: () => void;
  clearState: () => void;
  createPocket: (pocket: IPocket) => void;
  updatePocket: (id: string, values: IPocket) => void;
  deletePocket: (id: string) => void;
  getPocket: (id: string) => void;
  getPockets: (query: ITableQuery) => void;
}

export const DEFAULT_STATE_POCKET: IPocketState = {
  pockets: [],
  pocket: null,
  loading: false,
  error: null,
  count: 0,
  success: false,
  setLoading: () => {},
  clearState: () => {},
  createPocket: (pocket: IPocket) => {},
  updatePocket: (id: string, values: IPocket) => {},
  deletePocket: (id: string) => {},
  getPocket: (id: string) => {},
  getPockets: (query: ITableQuery) => {}
};

export const LOCAL_STORAGE_POCKET_DRAFT = 'local_storage_pocket_draft';

export const POCKET_POPULATE = [
  {
    path: 'user',
    model: 'User',
    select: 'id name'
  },
  {
    path: 'team',
    model: 'Team',
    select: 'id name owner',
    strictPopulate: false
  }
];

const POCKET_EVENT_TRIGGERS_FACEBOOK_LEAD_ADS = [POCKET_EVENT_TRIGGER_FACEBOOK_NEW_LEAD];

const POCKET_EVENT_ACTIONS_KRAQUEN = [POCKET_EVENT_ACTION_KRAQUEN_SAVE_LEAD];

const POCKET_EVENT_ACTIONS_SALES_U = [POCKET_EVENT_ACTION_SALES_U_WEBHOOK];

export const POCKET_EVENTS_LIBRARY = {
  [FACEBOOK_LEAD_ADS]: {
    triggers: POCKET_EVENT_TRIGGERS_FACEBOOK_LEAD_ADS,
    actions: []
  },
  [SOURCE_KRAQUEN]: {
    triggers: [],
    actions: POCKET_EVENT_ACTIONS_KRAQUEN
  },
  [SOURCE_SALES_U]: {
    triggers: [],
    actions: POCKET_EVENT_ACTIONS_SALES_U
  }
};
