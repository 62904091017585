import {
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_MATRIX,
  GET_MATRIX_IG,
  GET_MATRIX_SEGMENTATION,
  GET_FB_INSIGHTS,
  GET_GG_YOUTUBE,
  GET_GG_ANALYTICS,
  GET_GG_ADS,
  CREATE_FACEBOOK_ADS,
  GET_FACEBOOK_ADS,
  SET_LOADING_ADS,
  GET_FACEBOOK_ADSETS,
  GET_FACEBOOK_CREATIVES,
  SET_CLEAR_STATE_ADS,
  SET_LOADING_CREATIVES,
  GET_GOOGLE_ADS_AD_GROUP,
  GET_GOOGLE_ADS_KEYWORD,
  SET_LOADING_AD_GROUP,
  SET_LOADING_KEYWORD,
  GET_META_ADSETS,
  GET_TIKTOKS,
  GET_META_ADS_REPORT,
  SET_LOADING_REPORT
} from '../types';

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case SET_LOADING_REPORT:
      return {
        ...state,
        loadingReport: true
      };
    case GET_META_ADS_REPORT:
      return {
        ...state,
        metaAdsReport: action.payload,
        loadingReport: false
      };
    case GET_TIKTOKS:
      return {
        ...state,
        tiktokInfo: action.payload.data,
        tiktokVideos: action.payload.videos,
        loadingCharts: false,
        error: null
      };
    case GET_GOOGLE_ADS_KEYWORD:
      return {
        ...state,
        loadingKeyword: false,
        error: null,
        keywords: action.payload.data
      };
    case GET_GOOGLE_ADS_AD_GROUP:
      return {
        ...state,
        loadingAdGroup: false,
        error: null,
        adGroups: action.payload.data
      };
    case GET_FACEBOOK_CREATIVES:
      return {
        ...state,
        loadingAdCreatives: false,
        error: null,
        fbAdcreatives: action.payload.data
      };
    case GET_FACEBOOK_ADSETS:
      return {
        ...state,
        loadingAds: false,
        error: null,
        fbAdsets: action.payload.data
      };
    case GET_FACEBOOK_ADS:
      return {
        ...state,
        loadingCharts: false,
        error: null,
        fbData: action.payload
      };
    case CREATE_FACEBOOK_ADS:
      return {
        ...state,
        loadingCharts: false,
        error: null,
        responseCreated: action.payload
      };
    case GET_MATRIX_SEGMENTATION:
      return {
        ...state,
        matrixSegmentation: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_MATRIX:
      return {
        ...state,
        matrix: action.payload.data,
        matrixCampaigns: action.payload.campaigns,
        matrixAds: action.payload.adsets,
        matrixAccountName: action.payload.accountName,
        loadingCharts: false,
        error: null
      };
    case GET_META_ADSETS:
      return {
        ...state,
        matrix: action.payload.data,
        matrixCampaigns: action.payload.campaigns,
        matrixAccountName: action.payload.accountName,
        loadingCharts: false,
        error: null
      };
    case GET_MATRIX_IG:
      return {
        ...state,
        matrixIg: action.payload.data,
        igCards: action.payload.igCards,
        igAge: action.payload.igAge,
        loadingCharts: false,
        error: null
      };
    case GET_FB_INSIGHTS:
      return {
        ...state,
        fbInsights: action.payload.data,
        fbInsightsPost: action.payload.post,
        loadingCharts: false,
        error: null
      };
    case GET_GG_YOUTUBE:
      return {
        ...state,
        ytInfo: action.payload.data,
        ytVideos: action.payload.videos,
        loadingCharts: false,
        error: null
      };
    case GET_GG_ANALYTICS:
      return {
        ...state,
        analytics: action.payload,
        loadingCharts: false,
        error: null
      };
    case GET_GG_ADS:
      return {
        ...state,
        googleAds: action.payload.googleAds,
        googleAdsAudiencie: action.payload.googleAdsAudiencie,
        googleAdsCampaigns: action.payload.googleAdsCampaigns,
        loadingCharts: false,
        error: null
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingCharts: false
      };
    case CLEAR_STATE:
      return {
        matrix: {
          clicks: 0,
          impressions: 0,
          cpm: 0,
          leads: 0,
          spend: 0,
          costPerLead: 0
        },
        matrixAccountName: '',
        matrixIg: {},
        matrixSegmentation: [],
        matrixCampaigns: [],
        fbInsightsPost: [],
        fbInsight: {},
        ytInfo: {},
        ytVideos: [],
        googleAds: [],
        googleAdsCampaigns: [],
        googleAdsAudiencie: {},
        error: null,
        tiktokInfo: {},
        tiktokVideos: []
      };
    case SET_LOADING:
      return {
        ...state,
        loadingCharts: true
      };
    case SET_LOADING_ADS:
      return {
        ...state,
        loadingAds: true
      };
    case SET_LOADING_CREATIVES:
      return {
        ...state,
        loadingAdCreatives: true
      };
    case SET_CLEAR_STATE_ADS:
      return {
        fbAdsets: [],
        fbAdcreatives: [],
        error: null
      };
    case SET_LOADING_AD_GROUP:
      return {
        ...state,
        loadingAdGroup: true
      };
    case SET_LOADING_KEYWORD:
      return {
        ...state,
        loadingKeyword: true
      };
    default:
      return state;
  }
};
