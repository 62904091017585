import axios, { AxiosRequestConfig } from 'axios';
import api from 'api/api';
/**
 * Will upload an image to AWS S3 and return its url and key
 *
 * @param {File} file
 * @return {*}
 */
export const sendToAwsS3 = async (
  file: File,
  config: AxiosRequestConfig
): Promise<{
  url: string;
  key: string;
  name: string;
}> => {
  const { type, name } = file;

  const uploadConfig = await api.post('/uploads/image', { type, fileName: name }, config);

  const { url, key } = uploadConfig.data;

  await axios.put(url, file, {
    headers: { 'Content-Type': type }
  });

  return { url, key: process.env.NEXT_PUBLIC_AWS_S3_URL + key, name };
};
