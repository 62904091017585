import {
  CREATE_LEAD,
  CLEAR_STATE,
  SET_LOADING,
  SET_ERROR,
  GET_LEAD,
  UPDATE_LEAD,
  GET_LEADS,
  SEND_EMAIL,
  CLEAR_SNACKBAR,
  CREATE_NOTE,
  UPDATE_NOTE,
  DELETE_NOTE
} from '../types';
import { DEFAULT_LEAD_STATE } from './constants';
//eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case DELETE_NOTE: {
      return {
        ...state,
        notes: state.notes.filter((note) => note._id !== action.payload),
        success: action.successMessage
      };
    }
    case UPDATE_NOTE: {
      return {
        ...state,
        loading: false,
        notes: state.notes.map((note) => (note._id === action.payload._id ? action.payload : note)),
        success: action.successMessage
      };
    }
    case CREATE_NOTE: {
      return {
        ...state,
        loading: false,
        notes: [action.payload, ...(state?.notes || [])],
        success: action.successMessage
      };
    }
    case SEND_EMAIL: {
      return {
        ...state,
        email: action.payload,
        loading: false,
        error: null,
        success: action.successMessage
      };
    }
    case CLEAR_SNACKBAR:
      return { ...state, success: null, error: null };
    case CREATE_LEAD: {
      return {
        ...state,
        lead: action.payload,
        leads: [action.payload, ...state.leads],
        loading: false,
        error: null,
        success: action.successMessage
      };
    }
    case GET_LEAD: {
      const { lead, notes } = action.payload;
      return {
        ...state,
        lead,
        notes,
        loading: false,
        error: null
      };
    }

    case GET_LEADS: {
      return {
        ...state,
        count: action?.count || 0,
        leads: action.payload,
        loading: false,
        error: null
      };
    }
    case UPDATE_LEAD: {
      return {
        ...state,
        lead: { ...state?.lead, ...action.payload },
        loading: false,
        error: null
      };
    }
    case CLEAR_STATE:
      return {
        ...state,
        ...DEFAULT_LEAD_STATE
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
