import React, { useReducer } from 'react';
import FaceApiGraphContext from './faceApiGraphContext';
import FaceApiGraphReducer from './faceApiGraphReducer';
import FB_DEFAULT_STATE from '../../constants/Facebook/FB_DEFAULT_STATE';
import axios from 'axios';
import api from '../../api/api';
import {
  CLEAR_STATE,
  GET_FB_RESOURCE,
  GET_FACEBOOK_DOCUMENT,
  CREATE_FACEBOOK_DOCUMENT,
  UPDATE_FACEBOOK_DOCUMENT,
  RESEND_FACEBOOK_LEAD,
  SET_ERROR,
  SET_ERROR_LEADGEN,
  SET_LOADING,
  SET_LOADING_LEADGEN
} from '../types';
import { WARNING_STATUS_ICON, SUCCESS_STATUS_ICON } from '../../components/CustomAlert/constants/status';
import { RESOURCE_FACEBOOK_PAGE, RESOURCE_LEADGENS } from 'constants/Facebook/resources';
import CustomAlert from '../../components/CustomAlert';
import { useTranslation } from 'hooks/useTranslation';

const FaceApiGraphState = (props) => {
  const [state, dispatch] = useReducer(FaceApiGraphReducer, FB_DEFAULT_STATE);
  const { translate } = useTranslation();

  const subscribeFacebookPage = async (pageId, { page_access_token, user_access_token, selectedAccount }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      setLoading();
      const resourceName = 'facebookPage';

      const res = await api.post(`/${resourceName}/subscribe`, { selectedAccount, pageId, page_access_token, user_access_token }, config);
      dispatch({
        type: CREATE_FACEBOOK_DOCUMENT,
        payload: res.data.data,
        resourceName
      });
      CustomAlert({
        title: 'Subscribed Succesfull',
        icon: 'success'
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: SET_ERROR,
        payload: error?.response?.data?.error?.message || error?.response?.data?.error || error.message
      });
    }
  };

  const getFacebookResource = async ({ url, access_token, resourceName }) => {
    try {
      setLoading();
      if (!resourceName) throw new Error('resurceName is required');
      const res = await axios.get(`https://graph.facebook.com/v17.0/${url}`, {
        params: {
          access_token
        }
      });
      dispatch({
        type: GET_FB_RESOURCE,
        payload: res.data,
        resourceName
      });
    } catch (error) {
      if (resourceName === RESOURCE_LEADGENS) {
        const data = { accessTokenData: null, access_token: null };
        resourceName = RESOURCE_FACEBOOK_PAGE;
        const subscribeStatusMessage = translate('subscribeFacebookDocumentWarningMessage');
        updateFacebookDocument(state?.facebookPage?._id, {
          data,
          resourceName,
          successMessage: subscribeStatusMessage,
          iconAlert: WARNING_STATUS_ICON
        });
      } else {
        dispatch({
          type: SET_ERROR,
          payload: error?.response?.data?.error?.message || error?.response?.data?.error || error.message
        });
      }
    }
  };

  const setLoading = () => dispatch({ type: SET_LOADING });

  const setLoadingLeadgen = (leadgenId, loading = true) => dispatch({ type: SET_LOADING_LEADGEN, payload: { leadgenId, loading } });

  const clearState = (resourceName) => dispatch({ type: CLEAR_STATE, resourceName });

  const updateFacebookDocument = async (
    id,
    { data, resourceName, successMessage = 'Updated Succesfully', iconAlert = SUCCESS_STATUS_ICON }
  ) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      if (data?.leads) delete data.leads;
      const res = await api.put(`/${resourceName}/${id}`, { ...data }, config);
      CustomAlert({
        title: successMessage,
        icon: iconAlert
      });
      dispatch({
        type: UPDATE_FACEBOOK_DOCUMENT,
        payload: res.data.data,
        resourceName
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const createFacebookDocument = async ({ data, resourceName, successMessage = 'Created Succesfully' }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post(`/${resourceName}`, { ...data }, config);
      CustomAlert({
        title: successMessage,
        icon: 'success'
      });
      dispatch({
        type: CREATE_FACEBOOK_DOCUMENT,
        payload: res.data.data,
        resourceName
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };
  const getFacebookDocument = async (id, { resourceName, showError = true }) => {
    clearState(resourceName);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.get(`/${resourceName}/${id}`, config);
      dispatch({
        type: GET_FACEBOOK_DOCUMENT,
        payload: res.data.data,
        resourceName
      });
    } catch (err) {
      if (showError) dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const resendFacebookLead = async ({ leadgen, successMessage = 'Sended Succesfully' }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    setLoadingLeadgen(leadgen.id);
    try {
      const res = await api.post(`/facebookWebhook/resendFacebookLead`, { ...leadgen }, config);

      setLoadingLeadgen(leadgen.id, false);
      dispatch({
        type: RESEND_FACEBOOK_LEAD,
        payload: res.data.data
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: SET_ERROR_LEADGEN,
        payload: { leadgen, error: err.response.data }
      });
    }
  };

  return (
    <FaceApiGraphContext.Provider
      value={{
        ...state,
        getFacebookResource,
        createFacebookDocument,
        updateFacebookDocument,
        getFacebookDocument,
        resendFacebookLead,
        subscribeFacebookPage,
        clearState
      }}
    >
      {props.children}
    </FaceApiGraphContext.Provider>
  );
};

export default FaceApiGraphState;
