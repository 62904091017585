import React, { useReducer, createContext } from 'react';

import api from 'service/httpService';

import {
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  CREATE_EVENT,
  DELETE_EVENT,
  GET_EVENT,
  UPDATE_EVENT,
  GET_EVENTS,
  CLEAR_EVENT
} from '../types';
import { SUCCESS_STATUS_ICON, ERROR_STATUS_ICON } from 'components/CustomAlert/constants/status';
import { DEFAULT_EVENT_STATE } from './constants';
import { useTranslation } from 'hooks/useTranslation';

import CustomAlert from 'components/CustomAlert';

import eventReducer from './eventReducer';

export const EventContext = createContext();

const EventState = (props) => {
  const [state, dispatch] = useReducer(eventReducer, DEFAULT_EVENT_STATE);
  const { translate } = useTranslation();

  const createEvent = async ({ event, handleThen }) => {
    setLoading();
    try {
      const res = await api.post(`/event`, event);
      dispatch({ type: CREATE_EVENT, payload: res });
      CustomAlert({
        title: 'event created',
        icon: SUCCESS_STATUS_ICON,
        handleThen
      });
    } catch (err) {
      setError(err);
    }
  };

  const updateEvent = async ({ id, event, handleThen }) => {
    setLoading();
    try {
      const res = await api.put(`/event/${id}`, event);
      dispatch({ type: UPDATE_EVENT, payload: res });
      if (handleThen)
        CustomAlert({
          title: 'event updated',
          icon: SUCCESS_STATUS_ICON,
          handleThen
        });
    } catch (err) {
      setError(err);
    }
  };

  const deleteEvent = async ({ id, handleThen }) => {
    setLoading();

    try {
      const res = await api.delete(`/event/${id}`);
      dispatch({ type: DELETE_EVENT, payload: res });
      if (handleThen)
        CustomAlert({
          title: 'event deleted',
          icon: ERROR_STATUS_ICON,
          handleThen
        });
    } catch (err) {
      setError(err);
    }
  };

  const getEvent = async (eventId) => {
    setLoading();
    try {
      const res = await api.get(`/event/${eventId}`);
      dispatch({ type: GET_EVENT, payload: res });
    } catch (err) {
      setError(err);
    }
  };

  const getEvents = async (query) => {
    clearState();
    setLoading();
    try {
      const res = await api.post(`/event/advancedSearchDocuments`, query);
      dispatch({ type: GET_EVENTS, payload: res.data });
    } catch (err) {
      setError(err);
    }
  };

  //Clear Event
  const clearEvent = () => dispatch({ type: CLEAR_EVENT });

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  //Set Error Msg
  const setError = async (err) => dispatch({ type: SET_ERROR, payload: translate(err.response.data) });

  return (
    <EventContext.Provider
      value={{
        ...state,
        setLoading,
        clearState,
        clearEvent,
        createEvent,
        deleteEvent,
        updateEvent,
        getEvent,
        getEvents
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};

export default EventState;
