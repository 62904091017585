import CustomAlert from 'components/CustomAlert';
import { CREATE_APPOINTMENT, CLEAR_STATE, SET_LOADING, SET_ERROR, GET_APPOINTMENT, UPDATE_APPOINTMENT, GET_APPOINTMENTS } from '../types';
import { DEFAULT_APPOINTMENT_STATE } from './constants';
//eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case CREATE_APPOINTMENT: {
      state.appointments.unshift(action.payload);
      return {
        ...state,
        appointment: action.payload,
        loading: false,
        error: null
      };
    }
    case GET_APPOINTMENT: {
      return {
        ...state,
        appointment: action.payload,
        loading: false,
        error: null
      };
    }

    case GET_APPOINTMENTS: {
      return {
        ...state,
        appointments: action.payload,
        loading: false,
        error: null
      };
    }
    case UPDATE_APPOINTMENT: {
      state.appointments = state.appointments.map((appointment) => {
        if (appointment?._id === action?.payload?._id) return action.payload;
        return appointment;
      });
      return {
        ...state,
        appointment: action.payload,
        loading: false,
        error: null
      };
    }
    case CLEAR_STATE:
      return {
        ...state,
        ...DEFAULT_APPOINTMENT_STATE
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ERROR:
      CustomAlert({
        title: action?.payload?.error || action.payload,
        icon: 'error'
      });
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
