import { baseURL } from 'api/api';
import axios, { AxiosRequestConfig, Method } from 'axios';

class ApiService {
  private getConfig = () => {
    const token = typeof window !== 'undefined' && window.localStorage ? localStorage.getItem('token') : null;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {})
      }
    };
    return config;
  };

  private request = async (method: Method | undefined, url: string, data: any = null, config: AxiosRequestConfig = {}) => {
    try {
      const response = await axios({
        method,
        url: `${baseURL}${url}`,
        data,
        ...{ ...config, ...this.getConfig() }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  post = (url: string, data: any, config: AxiosRequestConfig = {}) => this.request('post', url, data, config);

  get = (url: string, config: AxiosRequestConfig = {}) => this.request('get', url, null, config);

  put = (url: string, data: any, config: AxiosRequestConfig = {}) => this.request('put', url, data, config);

  delete = (url: string, data: any = {}, config: AxiosRequestConfig = {}) => this.request('delete', url, data, config);
}

const apiService = new ApiService();

export default apiService;
