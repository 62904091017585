import CustomAlert from 'components/CustomAlert';
import { CREATE_TASK, CLEAR_STATE, SET_LOADING, SET_ERROR, GET_TASK, UPDATE_TASK, GET_TASKS } from '../types';
import { DEFAULT_TASK_STATE } from './constants';
//eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case CREATE_TASK: {
      state.tasks.unshift(action.payload);
      return {
        ...state,
        task: action.payload,
        loading: false,
        error: null
      };
    }
    case GET_TASK: {
      return {
        ...state,
        task: action.payload,
        loading: false,
        error: null
      };
    }

    case GET_TASKS: {
      return {
        ...state,
        tasks: action.payload,
        loading: false,
        error: null
      };
    }
    case UPDATE_TASK: {
      state.tasks = state.tasks.map((task) => {
        if (task?._id === action?.payload?._id) return action.payload;
        return task;
      });
      return {
        ...state,
        task: action.payload,
        loading: false,
        error: null
      };
    }
    case CLEAR_STATE:
      return {
        ...state,
        ...DEFAULT_TASK_STATE
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ERROR:
      CustomAlert({
        title: action?.payload?.error || action.payload,
        icon: 'error'
      });
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
